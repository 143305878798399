<script>
import { Icon } from '@iconify/vue2';

import { modalService } from '@/services/modal.service';
import { ModalName } from '@/values/modalName';

export default {
  name: 'AddCustomColumnCell',
  components: { Icon },
  props: {
    table: { type: Object, default: () => ({}) },
    column: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  methods: {
    onClick() {
      modalService().open(ModalName.AddCustomColumnModal);
    },
  },
};
</script>

<template>
  <button class="add-custom-column-button" @click="onClick">
    <Icon height="24" icon="tabler:circle-dashed-plus" width="24" />
  </button>
</template>

<style lang="scss" scoped>
  @use '@/style/colors.scss' as colors;

  .add-custom-column-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: colors.$primary-grey-light;
    transition: all 0.3s;

    &:hover {
      color: colors.$brand-color;
      background: colors.$secondary-light-brand;
    }

    &:active {
      color: colors.$brand-color-dark;
      background: colors.$primary-lavender;
    }
  }
</style>
