<script>
import { mapActions } from 'vuex';

import BaseMultiselect from '@/components/ui/BaseMultiselect.vue';
import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import BaseSolidButton from '@/components/ui/BaseSolidButton.vue';
import { BaseFormInput } from '@/components/ui/form';
import { modalService } from '@/services/modal.service';
import { ModalName } from '@/values/modalName';
import { CustomColumnType } from '@/widgets/CampaignInfluencersTable';

export default {
  name: 'AddCustomColumnModal',
  components: {
    BaseMultiselect,
    BaseSolidButton,
    BaseFormInput,
    BaseProvidedModal,
  },
  data: () => ({
    name: '',
    type: null,
    isLoading: false,
  }),
  computed: {
    id() {
      return ModalName.AddCustomColumnModal;
    },
    isValid() {
      return Boolean(this.type) && this.name.length >= 3;
    },
    typeOptions() {
      return structuredClone([
        { label: this.$gettext('Text'), value: CustomColumnType.Text },
        { label: this.$gettext('Numeric'), value: CustomColumnType.Numeric },
      ]);
    },
  },
  methods: {
    ...mapActions({
      addCustomColumn: 'agencyCabinetModule/addCustomColumn',
    }),
    async onSubmit() {
      try {
        this.isLoading = true;
        await this.addCustomColumn({ title: this.name, data_type: this.type });
        modalService().close(this.id);
      } catch {
        this.$notify({
          group: 'app',
          type: 'error',
          text: 'An error occurred when creating a custom column. Please try again or contact your administrator',
        });
      } finally {
        this.isLoading = false;
      }
    },
    onCancel() {
      modalService().close(this.id);
    },
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="id"
    :closable="true"
    :close-out-backdrop="false"
    :scrollable="false"
    :title="$gettext('Add column')"
    size="md"
    title-align="left"
    @hide="onCancel"
  >
    <div class="add-custom-column-form">
      <BaseFormInput v-model="name" :label="$gettext('Column name')" maxlength="64" minlength="3" />

      <BaseMultiselect
        v-model="type"
        :multiple="false"
        :options="typeOptions"
        :placeholder="$gettext('Column type')"
        :searchable="false"
        class="base-multiselect add-custom-column-form__select"
        close-on-select
        label-by="label"
        track-by="value"
      />

      <div class="add-custom-column-form__actions" style="margin-top: 16px">
        <BaseSolidButton
          :style="{ 'min-width': '185px' }"
          :text="$gettext('Cancel')"
          size="md"
          variant="primary-light"
          @click="onCancel"
        />
        <BaseSolidButton
          :disabled="!isValid"
          :loading="isLoading"
          :style="{ 'min-width': '185px' }"
          :text="$gettext('Add')"
          size="md"
          variant="primary"
          @click="onSubmit"
        />
      </div>
    </div>
  </BaseProvidedModal>
</template>

<style lang="scss" scoped>
  @use '@/style/colors.scss' as colors;

  .add-custom-column-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }

    &__select {
      background-color: transparent;
      height: 60px;

      :deep(.multiselect__placeholder) {
        font-family: Inter, sans-serif;
        color: colors.$primary-grey;
      }

      :deep(.multiselect__single) {
        font-family: Inter, sans-serif;
      }
    }
  }
</style>
