import { normalizeString, toNumber } from '@/functions/normalize';

export function toCustomColumnKey(id) {
  const normalizedId = toNumber(id, 0);
  return `custom-column-${normalizedId}`;
}

export function isCustomColumnKey(key) {
  return String(key ?? '').includes('custom-column-');
}

export function toCustomColumnId(key) {
  const normalizedKey = normalizeString(key);
  return toNumber(normalizedKey.replace('custom-column-', ''), 0);
}
