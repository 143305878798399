<script>
import { Icon } from '@iconify/vue2';
import { createNamespacedHelpers } from 'vuex';

import { TableCellPlaceholder } from '@/components/ui/tables';
import CellPopover from '@/components/ui/tables/fields/CellPopover.vue';
import { toArray } from '@/functions/normalize';
import { queryPluralText } from '@/functions/utils';
import { OfferStatus } from '@/values/offerStatus';

import NotesCellContent from './NotesCellContent.vue';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'BloggerNotesCell',
  components: { TableCellPlaceholder, CellPopover, NotesCellContent, Icon },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    innerValue: null,
  }),
  computed: {
    isDisabled() {
      return Boolean(this.row.originalValue.status === OfferStatus.Deleted);
    },
    displayName() {
      return this.row.originalValue.full_name ?? '';
    },
    notes() {
      return toArray(this.row.originalValue.agent_influencer_notes);
    },
    influencerId() {
      return this.row.originalValue.influencer_id ?? '';
    },
    notesLabel() {
      return queryPluralText(this.notes.length, [
        this.$gettext('заметка'),
        this.$gettext('заметки'),
        this.$gettext('заметок'),
      ]);
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({
      createNote: 'createBloggerNote',
      deleteNote: 'deleteBloggerNote',
    }),
    async onSubmit(value) {
      await this.createNote({ ...value, influencer: this.influencerId });
    },
    async onRemove(id) {
      const headerTitle = this.$gettext('Delete note');
      const headerDescription = this.$gettext(
        'Are you sure you want to delete the selected note? This action cannot be undone',
      );
      await this.deleteNote({ id: [id], textOption: { headerTitle, headerDescription } });
    },
  },
};
</script>

<template>
  <CellPopover :disabled="isDisabled">
    <template #trigger>
      <div :data-disabled="isDisabled" class="notes-cell">
        <span v-if="notes.length" class="notes-cell__tag">
          <Icon icon="bx:note" width="20" />
          {{ notesLabel }}
        </span>
        <div v-if="!isDisabled">
          <TableCellPlaceholder position="left" variant="modal" />
        </div>
      </div>
    </template>
    <template #content="{ close }">
      <NotesCellContent
        :influencer="displayName"
        :notes="notes"
        :placeholder-description="$gettext('Add a note for the blogger.')"
        :placeholder-title="$gettext('No notes')"
        :title="`${$gettext('Notes about blogger')} ${displayName}`"
        @submit="onSubmit"
        @close:note="close"
        @delete:note="onRemove"
      />
    </template>
  </CellPopover>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .notes-cell {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow: hidden;
    text-overflow: ellipsis;

    &[data-disabled='true'] {
      pointer-events: none;
      opacity: 0.5;
    }

    &__tag {
      @include fonts.fontSize-normal;
      @include fonts.raleway-medium;
      border-radius: 8px;
      padding: 5px 10px;
      background-color: colors.$primary-background-dark;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      min-width: 72px;
      max-width: 100%;
    }
  }
</style>
