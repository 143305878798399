export const OfferStatus = Object.freeze({
  ApprovedDraft: 'approved_draft',
  ApprovedPrice: 'approved_price',
  ApprovedPublish: 'approved_publish',
  Completed: 'completed',
  Demo: 'demo',
  New: 'new',
  Published: 'published',
  Rejected: 'rejected',
  RejectedDraft: 'rejected_draft',
  RejectedPrice: 'rejected_price',
  RejectedPublish: 'rejected_publish',
  RejectedSpecification: 'rejected_tz',
  SentDraft: 'sent_draft',
  SentPrice: 'sent_price',
  SentSpecification: 'sent_tz',
  Deleted: 'deleted',
});

export const createOfferStatusConfig = (texts = {}) => {
  return Object.freeze({
    [OfferStatus.New]: {
      title: texts[OfferStatus.New] || 'New',
      palette: 'info',
      icon: '',
      legacy: {
        arrow: true,
        arrowDir: 'up',
      },
    },
    [OfferStatus.Demo]: {
      title: texts[OfferStatus.Demo] || 'Demo',
      palette: 'brand',
      icon: '',
      legacy: {
        arrow: false,
        arrowDir: 'down',
      },
    },
    [OfferStatus.SentSpecification]: {
      title: texts[OfferStatus.SentSpecification] || 'Sent specification',
      palette: 'info',
      info: 'bi:arrow-down-short',
      legacy: {
        arrow: true,
        arrowDir: 'down',
      },
    },
    [OfferStatus.RejectedSpecification]: {
      title: texts[OfferStatus.RejectedSpecification] || 'Rejected specification',
      palette: 'failure',
      icon: '',
      legacy: {
        arrow: false,
        arrowDir: 'down',
      },
    },
    [OfferStatus.SentPrice]: {
      title: texts[OfferStatus.SentPrice] || 'Sent price',
      palette: 'info',
      icon: '',
      legacy: {
        arrow: true,
        arrowDir: 'up',
      },
    },
    [OfferStatus.ApprovedPrice]: {
      title: texts[OfferStatus.ApprovedPrice] || 'Approved price',
      palette: 'info',
      icon: '',
      legacy: {
        arrow: true,
        arrowDir: 'down',
      },
    },
    [OfferStatus.RejectedPrice]: {
      title: texts[OfferStatus.RejectedPrice] || 'Rejected price',
      palette: 'failure',
      icon: '',
      legacy: {
        arrow: false,
        arrowDir: 'down',
      },
    },
    [OfferStatus.SentDraft]: {
      title: texts[OfferStatus.SentDraft] || 'Sent draft',
      palette: 'info',
      icon: '',
      legacy: {
        arrow: true,
        arrowDir: 'up',
      },
    },
    [OfferStatus.ApprovedDraft]: {
      title: texts[OfferStatus.ApprovedDraft] || 'Approved draft',
      palette: 'info',
      icon: '',
      legacy: {
        arrow: true,
        arrowDir: 'down',
      },
    },
    [OfferStatus.RejectedDraft]: {
      title: texts[OfferStatus.RejectedDraft] || 'Rejected draft',
      palette: 'failure',
      icon: '',
      legacy: {
        arrow: false,
        arrowDir: 'down',
      },
    },
    [OfferStatus.Published]: {
      title: texts[OfferStatus.Published] || 'Published',
      palette: 'info',
      icon: '',
      legacy: {
        arrow: true,
        arrowDir: 'up',
      },
    },
    [OfferStatus.ApprovedPublish]: {
      title: texts[OfferStatus.ApprovedPublish] || 'Approved publish',
      palette: 'info',
      icon: '',
      legacy: {
        arrow: false,
        arrowDir: 'down',
      },
    },
    [OfferStatus.RejectedPublish]: {
      title: texts[OfferStatus.RejectedPublish] || 'Rejected publish',
      palette: 'failure',
      icon: '',
      legacy: {
        arrow: false,
        arrowDir: 'down',
      },
    },
    [OfferStatus.Completed]: {
      title: texts[OfferStatus.Completed] || 'Completed',
      palette: 'success',
      icon: '',
      legacy: {
        arrow: false,
        arrowDir: 'down',
      },
    },
    [OfferStatus.Rejected]: {
      title: texts[OfferStatus.Rejected] || 'Rejected',
      palette: 'failure',
      icon: '',
      legacy: {
        arrow: false,
        arrowDir: 'down',
      },
    },
    [OfferStatus.Deleted]: {
      title: texts[OfferStatus.Deleted] || 'Deleted',
      palette: 'failure',
      icon: '',
      legacy: {
        arrow: false,
        arrowDir: 'down',
      },
    },
  });
};
