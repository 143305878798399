<script>
import isEqual from 'lodash-es/isEqual';
import { createNamespacedHelpers } from 'vuex';

import { TableCellPlaceholder, TextCellField } from '@/components/ui/tables';
import { toFloatString, toNumber } from '@/functions/normalize';
import { OfferStatus } from '@/values/offerStatus';
import { createTablePatchPayload } from '@/widgets/CampaignInfluencersTable';

import { CustomColumnType } from './values';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'CustomColumnCell',
  components: { TableCellPlaceholder, TextCellField },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    innerValue: '',
  }),
  computed: {
    isDisabled() {
      return Boolean(this.row.originalValue.status === OfferStatus.Deleted);
    },
    columnKey() {
      return this.cell.col?.key ?? '';
    },
    meta() {
      return this.columnDefs[this.columnKey]?.meta || {};
    },
    columnId() {
      return this.meta.id;
    },
    columnType() {
      return this.meta.data_type || CustomColumnType.Text;
    },
    value() {
      return this.cell.value ?? '';
    },
    isTextType() {
      return this.columnType === CustomColumnType.Text;
    },
    inputType() {
      return this.isTextType ? 'text' : 'number';
    },
    formattedValue() {
      switch (this.columnType) {
        case CustomColumnType.Numeric:
          return toFloatString(toNumber(this.innerValue));
        default:
          return this.innerValue ?? '';
      }
    },
  },
  watch: {
    value: {
      handler(value) {
        this.innerValue = value ?? '';
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({ updateRow: 'updateRow' }),
    normalizeInput(value) {
      switch (this.columnType) {
        case CustomColumnType.Numeric:
          return value?.replace(/\s+/g, '') ?? '';
        default:
          return String(value ?? '').trim();
      }
    },
    normalizeValue(value) {
      switch (this.columnType) {
        case CustomColumnType.Numeric:
          return toNumber(value, 0);
        default:
          return value || '';
      }
    },
    onRevert() {
      this.innerValue = this.value;
    },
    async onSubmit(e) {
      const inputValue = this.normalizeInput(e.target.value);
      const prevValue = this.normalizeValue(this.innerValue);
      const nextValue = this.normalizeValue(inputValue);

      if (isEqual(prevValue, nextValue)) {
        return;
      }

      const prevPayload = this.row.originalValue.agent_extra_columns ?? {};
      const payload = createTablePatchPayload(this.row.originalValue, {
        agent_extra_columns: { ...prevPayload, [this.columnId]: nextValue },
      });
      this.innerValue = nextValue;
      const isSubmitted = await this.updateRow(payload);
      !isSubmitted && this.onRevert();
    },
  },
};
</script>

<template>
  <TextCellField
    ref="cell"
    :as="isTextType ? 'textarea' : 'input'"
    :disabled="isDisabled"
    :max-length="512"
    :type="inputType"
    :value="innerValue"
    @revert="onRevert"
    @submit="onSubmit"
  >
    <span
      v-if="innerValue"
      :title="formattedValue"
      class="custom-cell__value"
      v-text="formattedValue"
    />
    <TableCellPlaceholder v-else-if="!isDisabled" />
  </TextCellField>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  $max-lines: 10;

  .custom-cell {
    &__value {
      display: -webkit-box;
      -webkit-line-clamp: $max-lines;
      line-clamp: $max-lines;
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;
    }
  }
</style>
