<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'BaseMultiselect',
  components: { Multiselect },
  props: {
    value: { type: [String, Number, Array, Object], default: null },
    options: { type: Array, required: true },
    trackBy: { type: String, default: null },
    label: { type: String, default: null },
    labelBy: { type: String, default: null },
    multiple: { type: Boolean, default: false },
    clearOnSelect: { type: Boolean, default: false },
    closeOnSelect: { type: Boolean, default: true },
    searchable: { type: Boolean, default: true },
    placeholder: { type: String, default: 'Select an option' },
  },
  emits: ['open', 'input'],
  data: () => ({
    internalValue: null,
  }),
  computed: {
    isObjectOptions() {
      return typeof this.options[0] === 'object';
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.internalValue = this.getInternalValue(newValue);
      },
      immediate: true,
    },
  },
  created() {
    this.internalValue = this.getInternalValue(this.value);
  },
  methods: {
    getInternalValue(value) {
      if (this.multiple && Array.isArray(value)) {
        return this.isObjectOptions
          ? this.options.filter((option) => value.includes(option[this.trackBy]))
          : this.options.filter((option) => value.includes(option));
      } else {
        return this.isObjectOptions
          ? this.options.find((option) => option[this.trackBy] === value) || null
          : this.options.find((option) => option === value) || null;
      }
    },
    onSelect(selectedOption) {
      const selectedValue = this.multiple
        ? this.isObjectOptions
          ? selectedOption.map((option) => option[this.trackBy])
          : selectedOption
        : this.isObjectOptions
          ? selectedOption[this.trackBy]
          : selectedOption;

      this.$emit('input', selectedValue);
    },
    onRemove(removedOption) {
      if (this.multiple) {
        const updatedValue = this.internalValue
          .filter((option) =>
            this.isObjectOptions
              ? option[this.trackBy] !== removedOption[this.trackBy]
              : option !== removedOption,
          )
          .map((option) => (this.isObjectOptions ? option[this.trackBy] : option));

        this.$emit('input', updatedValue);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>

<template>
  <Multiselect
    v-model="internalValue"
    :clear-on-select="clearOnSelect"
    :close-on-select="closeOnSelect"
    :label="isObjectOptions ? label || labelBy : ''"
    :multiple="multiple"
    :options="options"
    :placeholder="placeholder"
    :searchable="searchable"
    :track-by="isObjectOptions ? trackBy : ''"
    class="base-multiselect"
    @open="$emit('open')"
    @remove="onRemove"
    @select="onSelect"
  >
    <template #noOptions>
      <translate>Please wait</translate>
    </template>
    <template #noResult>
      <translate>No elements found. Consider changing the search query.</translate>
    </template>
  </Multiselect>
</template>
