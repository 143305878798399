export function createCampaignDb() {
  const request = indexedDB.open('agency-cabinet', 1);
  const ObjectStoreName = 'Campaigns';
  let db = null;

  request.onupgradeneeded = (event) => {
    const db = event.target.result;
    db.createObjectStore(ObjectStoreName, { keyPath: 'id', autoIncrement: false });
  };

  request.onsuccess = () => {
    db = request.result;
    console.log('Database opened successfully');
  };

  request.onerror = (event) => {
    console.error('Error opening database:', event.target.errorCode);
  };

  const addRecord = (data) => {
    if (!db) return Promise.reject();

    const transaction = db.transaction([ObjectStoreName], 'readwrite');
    const objectStore = transaction.objectStore(ObjectStoreName);
    const request = objectStore.add(data);

    const { promise, reject, resolve } = Promise.withResolvers();

    request.onsuccess = () => resolve(true);
    request.onerror = (e) => reject(e);

    return promise;
  };

  const getRecord = (id) => {
    if (!db) return Promise.reject();

    const transaction = db.transaction([ObjectStoreName], 'readonly');
    const objectStore = transaction.objectStore(ObjectStoreName);
    const request = objectStore.get(id);

    const { promise, reject, resolve } = Promise.withResolvers();

    request.onsuccess = (e) => resolve(e.target.result);
    request.onerror = (e) => reject(e);

    return promise;
  };

  const updateRecord = (id, data) => {
    if (!db) return Promise.reject();

    const transaction = db.transaction([ObjectStoreName], 'readwrite');
    const objectStore = transaction.objectStore(ObjectStoreName);
    const request = objectStore.put({ ...data, id });

    const { promise, reject, resolve } = Promise.withResolvers();

    request.onsuccess = () => resolve(true);
    request.onerror = (e) => reject(e);

    return promise;
  };

  const removeRecord = (id) => {
    if (!db) return Promise.reject();

    const transaction = db.transaction([ObjectStoreName], 'readwrite');
    const objectStore = transaction.objectStore(ObjectStoreName);
    const request = objectStore.delete(id);

    const { promise, reject, resolve } = Promise.withResolvers();

    request.onsuccess = () => resolve(true);
    request.onerror = (e) => reject(e);

    return promise;
  };

  return { getRecord, addRecord, updateRecord, removeRecord };
}
