<script>
import { createNamespacedHelpers } from 'vuex';

import BaseButton from '@/components/ui/BaseButton.vue';
import BaseSolidButton from '@/components/ui/BaseSolidButton.vue';
import Loading from '@/components/ui/Loading.vue';
import { toNonUndefined, toNumberString } from '@/functions/normalize';
import { ComponentState } from '@/values/componentState';
import { Currency } from '@/values/payment';

const SummaryItemKey = Object.freeze({
  Followers: 'follower_count',
  TargetReach: 'target_reach',
  ActualReach: 'actual_reach',
  FollowersReach: 'reach_followers',
  Actions: 'actions',
  Price: 'price',
  PriceUSD: 'usd_price',
  TotalPrice: 'total_price',
  TotalPriceUSD: 'total_usd_price',
  PaidAmount: 'spend',
});
const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'TableSummaryPopover',
  components: { Loading, BaseSolidButton, BaseButton },
  data: () => ({
    stateComponent: null,
    summaryData: {},
  }),
  computed: {
    ComponentState() {
      return ComponentState;
    },
    summaryItemTitles() {
      return {
        [SummaryItemKey.Followers]: this.$gettext('Followers'),
        [SummaryItemKey.TargetReach]: this.$gettext('Targeted reach'),
        [SummaryItemKey.ActualReach]: this.$gettext('Actual reach'),
        [SummaryItemKey.FollowersReach]: this.$gettext('Followers reach'),
        [SummaryItemKey.Actions]: this.$gettext('Actions'),
        [SummaryItemKey.Price]: this.$gettext('Price'),
        [SummaryItemKey.TotalPrice]: this.$gettext('Total price'),
        [SummaryItemKey.PaidAmount]: this.$gettext('Paid amount'),
        [SummaryItemKey.PriceUSD]: this.$gettext('Price'),
        [SummaryItemKey.TotalPriceUSD]: this.$gettext('Total price'),
      };
    },
    currencyTicker() {
      return this.summaryData.currency || '';
    },
    hasPaidAmount() {
      return !!this.summaryData.aggregated_data?.[SummaryItemKey.PaidAmount];
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({ fetchSummaryData: 'fetchSummaryData' }),

    async fetchData() {
      this.stateComponent = ComponentState.isLoading;
      try {
        const response = await this.fetchSummaryData();
        this.summaryData = response.data;
        this.stateComponent = ComponentState.isSuccess;
      } catch (error) {
        this.stateComponent = ComponentState.isError;
      }
    },
    handleHide() {
      this.stateComponent = ComponentState.isLoading;
      this.summaryData = {};
    },
    getSummaryItemTitle(key) {
      return this.summaryItemTitles[key] || key;
    },
    transformAggregatedData(aggregatedData) {
      return Object.keys(SummaryItemKey)
        .map((key) => SummaryItemKey[key])
        .filter(
          (key) =>
            toNonUndefined(aggregatedData[key]) &&
            !(
              this.hasPaidAmount &&
              (key === SummaryItemKey.PriceUSD || key === SummaryItemKey.TotalPriceUSD)
            ),
        )
        .map((key) => {
          const { formattedValue, formattedValueUSD } = this.getFormattedValue(
            aggregatedData[key],
            aggregatedData,
            key,
          );
          return {
            key,
            formattedValue: [
              SummaryItemKey.Price,
              SummaryItemKey.PaidAmount,
              SummaryItemKey.TotalPrice,
            ].includes(key)
              ? `${formattedValue} ${this.currencyTicker}`
              : formattedValue,
            ...(formattedValueUSD && { formattedValueUSD }),
          };
        });
    },
    getFormattedValue(value, aggregatedData, key) {
      let formattedValue = toNumberString(value || 0);
      let formattedValueUSD = null;

      if (this.hasPaidAmount && this.currencyTicker !== Currency.USD) {
        switch (key) {
          case SummaryItemKey.Price:
            formattedValueUSD = toNumberString(
              aggregatedData[SummaryItemKey.PriceUSD],
              Currency.USD,
              true,
            );
            break;
          case SummaryItemKey.TotalPrice:
            formattedValueUSD = toNumberString(
              aggregatedData[SummaryItemKey.TotalPriceUSD],
              Currency.USD,
              true,
            );
            break;
        }
      } else if (!this.hasPaidAmount) {
        switch (key) {
          case SummaryItemKey.PriceUSD:
            formattedValue = toNumberString(value, Currency.USD, true);
            break;
          case SummaryItemKey.TotalPriceUSD:
            formattedValue = toNumberString(value, Currency.USD, true);
            break;
        }
      }

      return { formattedValue, formattedValueUSD };
    },
  },
};
</script>

<template>
  <b-dropdown
    class="dropdownButton"
    no-caret
    right
    variant="none"
    @hide="handleHide"
    @show="fetchData"
  >
    <template #button-content>
      <BaseButton color-mode="tertiary-accent" icon="tabler:sum" icon-only />
    </template>

    <div class="dropdown-content">
      <Loading v-if="stateComponent === ComponentState.isLoading" />
      <div v-else-if="stateComponent === ComponentState.isSuccess" class="summary-content">
        <span class="summary-content__header">
          <translate>Total of all values</translate>
          ({{ summaryData.counted_offers }})
        </span>
        <ul v-if="summaryData.aggregated_data" class="summary-content__list">
          <li
            v-for="{ key, formattedValue, formattedValueUSD } in transformAggregatedData(
              summaryData.aggregated_data,
            )"
            :key="key"
            class="summary-content__item"
          >
            <span class="summary-content__item-label">{{ getSummaryItemTitle(key) }}</span>
            <div class="summary-content__group">
              <div class="summary-content__value">
                {{ formattedValue }}
              </div>
              <div v-if="formattedValueUSD" class="summary-content__general-value">
                {{ formattedValueUSD }}
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div v-else-if="stateComponent === ComponentState.isError" class="summary-content__failure">
        <translate>Something went wrong</translate>
        <BaseSolidButton
          :style="{ 'min-width': '185px' }"
          :text="$gettext('Retry request')"
          size="md"
          variant="primary-light"
          @click="fetchData"
        />
      </div>
    </div>
  </b-dropdown>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .dropdownButton:deep(.btn) {
    padding: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:active {
      border-color: transparent;
    }
  }

  .dropdownButton:deep(.dropdown-menu) {
    background: colors.$primary-white;
    box-shadow: 1px 3px 20px 0 #302a6f0f;
    border-radius: 16px;
    padding: 20px;
    border: 1px solid rgba(1, 187, 236, 0.05);
    min-width: 340px;
    overflow-y: auto;
  }

  .summary-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include fonts.fontSize-big;
    color: colors.$primary-black;

    &__header {
      @include fonts.raleway-bold;
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-label {
        color: colors.$primary-grey;
      }
    }

    &__group {
      @include fonts.raleway-medium;
      display: flex;
      flex-direction: column;
      align-items: end;
    }

    &__general-value {
      @include fonts.fontSize-small;
      color: colors.$primary-grey-light;
    }

    &__failure {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      @include fonts.fontSize-big;
      @include fonts.raleway-bold;
      color: colors.$primary-black;
    }
  }
</style>
