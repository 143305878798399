import debounce from 'lodash-es/debounce';
import isEqual from 'lodash-es/isEqual';

import ApiService from '@/api/ApiService';
import { endpoints } from '@/api/endpoints';
import { buildGetParams } from '@/functions/buildGetParams';
import { downloadFile } from '@/functions/download';
import { toArray } from '@/functions/normalize';
import { modalService } from '@/services/modal.service';
import store from '@/store';
import { createCampaignDb } from '@/values/campaignDb';
import { FileType } from '@/values/fileType';
import { ModalName } from '@/values/modalName';
import { WaitState } from '@/values/waitState';
import {
  callConfirmDeletionModal,
  ColumnKey,
  DefaultHidingState,
  DefaultPaginationState,
  DefaultStatusFilterState,
  toCustomColumnKey,
} from '@/widgets/CampaignInfluencersTable';

const api = new ApiService({
  onCreated(client) {
    client.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response?.data?.code === 'outdated') {
          modalService().open(ModalName.OutdatedModal, {
            refreshData: async () => {
              await store
                .dispatch('agencyCabinetModule/fetchData')
                .finally(modalService().closeAll);
            },
          });
          return Promise.reject(error);
        }

        const request = error?.config;
        const isRetryRequest = request?.headers['X-Can-Retry-Request'];

        if (!request || !isRetryRequest) {
          return Promise.reject(error);
        }

        try {
          await new Promise((resolve, reject) => {
            modalService().open(ModalName.FailureModal, {
              errorMessage: error.response?.data?.detail || '',
              errorStatus: error.response?.status || 500,
              sendRequest: async () => {
                Reflect.deleteProperty(request.headers, 'X-Can-Retry-Request');
                return await api.client(request);
              },
              rejectRequest: () => {
                modalService().close(ModalName.FailureModal);
                reject();
              },
            });
          });
          return Promise.resolve(null);
        } catch (error) {
          modalService().close(ModalName.FailureModal);
          return Promise.reject(error);
        }
      },
    );
  },
});

const database = createCampaignDb();

const getDefaultCampaignRecord = (id) => ({
  id: String(id),
  hidingState: structuredClone(DefaultHidingState),
  paginationState: structuredClone(DefaultPaginationState),
  statusFilterState: structuredClone(DefaultStatusFilterState),
});

export default {
  namespaced: true,
  state: () => ({
    table: null,
    data: [],
    customColumns: {},
    sortingState: [{ key: ColumnKey.InfluencerInfo, desc: false }],
    selectionState: [],
    hidingState: structuredClone(DefaultHidingState),
    paginationState: structuredClone(DefaultPaginationState),
    statusFilter: structuredClone(DefaultStatusFilterState),
    waitState: WaitState.default,
    currentCampaignId: null,
  }),
  mutations: {
    setCurrentCampaignId(state, nextState) {
      state.currentCampaignId = nextState;
    },
    setData(state, nextState) {
      state.data = nextState.results;
    },
    setCampaignState(state, nextState) {
      state.hidingState = nextState.hidingState;
      state.statusFilter = nextState.statusFilterState;
      state.paginationState = nextState.paginationState;
      database.updateRecord(String(state.currentCampaignId), nextState);
    },
    setCustomColumns(state, nextState) {
      state.customColumns = toArray(nextState).reduce((dict, column) => {
        dict[column.id] = column;
        return dict;
      }, {});
    },
    setNewRowsData(state, nextState = []) {
      if (!nextState.length) return;
      nextState.forEach((newRow) => {
        const index = state.data.findIndex((row) => row.id === newRow.id);
        if (index !== -1) {
          state.data = [...state.data.slice(0, index), newRow, ...state.data.slice(index + 1)];
        }
      });
    },
    removeCustomColumnValuesFromData(state, customColumnId) {
      const nextData = [...state.data];
      for (const row of nextData) {
        if (typeof row.agent_extra_columns === 'object') {
          delete row.agent_extra_columns[customColumnId];
        }
      }
      state.data = nextData;
    },
    setTable(state, nextInstance) {
      state.table = nextInstance;
    },
    setStatusFilter(state, nextPartialState) {
      const nextState = { ...state.statusFilter, ...nextPartialState };
      state.statusFilter = nextState;
      database.updateRecord(String(state.currentCampaignId), {
        statusFilterState: nextState,
        paginationState: state.paginationState,
        hidingState: state.hidingState,
      });
    },
    setHidingState(state, nextPartialState) {
      const nextState = {
        ...state.hidingState,
        ...nextPartialState,
      };
      state.hidingState = nextState;
      database.updateRecord(String(state.currentCampaignId), {
        statusFilterState: state.statusFilter,
        paginationState: state.paginationState,
        hidingState: nextState,
      });
    },
    setSortingState(state, nextState = []) {
      state.sortingState = nextState;
    },
    setPaginationState(state, nextPartialState) {
      const nextState = {
        ...state.paginationState,
        nextPartialState,
      };
      state.paginationState = nextState;
      database.updateRecord(String(state.currentCampaignId), {
        statusFilterState: state.statusFilter,
        paginationState: nextState,
        hidingState: state.hidingState,
      });
    },
    setTotalCount(state, totalCount) {
      const nextState = {
        ...state.paginationState,
        totalCount,
      };
      state.paginationState = nextState;
      database.updateRecord(String(state.currentCampaignId), {
        statusFilterState: state.statusFilter,
        paginationState: nextState,
        hidingState: state.hidingState,
      });
    },
    setSelectionState(state, { positions }) {
      const nextState = Array.isArray(positions) ? positions : [];
      state.selectionState = nextState.map(Number);
    },
    clearSelectionState(state) {
      state.waitState = WaitState.default;
      state.selectionState = [];
      setTimeout(() => state.table?.context?.selection?.reset());
    },
    setWaitState(state, stateStatus) {
      state.waitState = stateStatus;
    },
  },
  actions: {
    fetchData: debounce(async ({ commit, dispatch, state }) => {
      commit('setWaitState', WaitState.isLoading);

      const { statusFilter, paginationState, sortingState, currentCampaignId } = state;
      const filteredStatuses = Object.keys(statusFilter)
        .filter((key) => statusFilter[key])
        .join(',');

      const allTrue = Object.values(statusFilter).every(Boolean);
      const allFalse = Object.values(statusFilter).every((value) => !value);
      const pagination = paginationState ?? DefaultPaginationState;

      const query = buildGetParams({
        page: pagination.page,
        per_page: pagination.perPage,
        ordering: sortingState.map(({ key, desc }) => `${desc ? '-' : ''}${key}`).join('&'),
        ...(allTrue ? {} : { statuses: allFalse ? '' : filteredStatuses }),
      });

      try {
        let customColumns = [];

        await Promise.all([
          api.get(`${endpoints.agent_campaign}${currentCampaignId}/offers?${query}`).then((r) => {
            commit('setData', r.data);
            commit('setTotalCount', r.data.count);
          }),
          api.get(`${endpoints.agent_campaign}${currentCampaignId}/extra_columns`).then((r) => {
            customColumns = r.data;
          }),
        ]);

        await dispatch('revalidateTableState', customColumns);
      } finally {
        commit('setWaitState', WaitState.default);
      }
    }, 750),
    fetchSummaryData: async ({ state }) => {
      return await api.get(`${endpoints.agent_campaign}${state.currentCampaignId}/total`);
    },
    async updateRow({ commit, state }, rowData) {
      commit('setWaitState', WaitState.isLoading);
      try {
        const payload = Array.isArray(rowData) ? rowData : [rowData];
        const url = `${endpoints.agent_campaign}${state.currentCampaignId}/offers`;
        const { data } = await api.patch(url, payload, { 'X-Can-Retry-Request': 'true' });

        commit('setWaitState', WaitState.isSuccess);
        commit('setNewRowsData', data);
        return true;
      } catch {
        commit('setWaitState', WaitState.isFailure);
        return false;
      }
    },
    async removeRows({ commit, state, dispatch }, payload) {
      const bloggerIds = state.selectionState.map((index) => state.data[index].id);
      const url = `${endpoints.agent_campaign}${state.currentCampaignId}/offers`;

      try {
        await callConfirmDeletionModal(api, url, bloggerIds, payload.textOption);
        commit('clearSelectionState');
        commit('setWaitState', WaitState.default);
        await dispatch('fetchData');
      } catch (e) {
        console.error(e);
      }
    },
    async downloadReport({ state }) {
      try {
        const endpoint = 'getCampaignInfluencersExcel';
        const blob = await store.dispatch(endpoint, { id: state.currentCampaignId });
        const file = new Blob([blob], { type: FileType.Xlsx.type });
        downloadFile(URL.createObjectURL(file), `Influencers.${FileType.Xlsx.ext}`);
      } catch (e) {
        console.error(e);
      }
    },
    clearState({ commit, state }) {
      state.table?.context.core.setRows([]);
      commit('setData', []);
      commit('setPaginationState', structuredClone(DefaultPaginationState));
      commit('setTable', null);
    },
    // CONTENT API
    async createContent({ commit, state }, payload) {
      const prevOffer = state.data.find((item) => item.id === payload.offer);
      if (!prevOffer) return null;

      try {
        commit('setWaitState', WaitState.isLoading);

        const nextOffer = { ...prevOffer };
        const nextContent = nextOffer[payload.type] ?? [];

        const formData = new FormData();
        formData.append('offer', payload.offer);
        formData.append('attachment', payload.attachment);

        const { data } = await api.post(
          `${endpoints.agent_campaign}${state.currentCampaignId}/offer_${payload.type}`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        nextContent.push(data);
        nextOffer[payload.type] = nextContent;
        commit('setNewRowsData', nextOffer);
        commit('setWaitState', WaitState.isSuccess);
        return data;
      } catch (error) {
        commit('setWaitState', WaitState.isFailure);
        return null;
      }
    },
    async removeContent({ commit, state, dispatch }, payload) {
      try {
        const url = `${endpoints.agent_campaign}${state.currentCampaignId}/offer_${payload.type}`;

        await callConfirmDeletionModal(api, url, payload.attachment, payload.textOption);
        await dispatch('fetchData');
        commit('setWaitState', WaitState.default);
      } catch (e) {
        console.error(e);
      }
    },
    // OFFER NOTES API
    async createOfferNote({ commit, state }, payload) {
      const prevOffer = state.data.find((item) => item.id === payload.offer);
      if (!prevOffer) return null;

      try {
        commit('setWaitState', WaitState.isLoading);

        const nextOffer = { ...prevOffer };
        const nextNotes = nextOffer.agent_offer_notes ?? [];

        const formData = new FormData();
        Object.entries(payload).forEach(([k, v]) => v && formData.append(k, v));

        const { data } = await api.post(
          `${endpoints.agent_campaign}${state.currentCampaignId}/offer_notes`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        nextNotes.push(data);
        nextOffer.agent_offer_notes = nextNotes;
        commit('setNewRowsData', nextOffer);
        commit('setWaitState', WaitState.isSuccess);
        return data;
      } catch (error) {
        commit('setWaitState', WaitState.isFailure);
        return null;
      }
    },
    async deleteOfferNote({ commit, state, dispatch }, payload) {
      try {
        const url = `${endpoints.agent_campaign}${state.currentCampaignId}/offer_notes`;
        await callConfirmDeletionModal(api, url, payload.id, payload.textOption);
        await dispatch('fetchData');

        commit('setSelectionState', []);
        commit('setWaitState', WaitState.default);
      } catch (e) {
        console.error(e);
      }
    },
    // BLOGGER NOTES API
    async createBloggerNote({ commit, state }, payload) {
      const prevOffer = state.data.find((item) => item.influencer_id === payload.influencer);
      if (!prevOffer) return null;

      try {
        commit('setWaitState', WaitState.isLoading);

        const nextOffer = { ...prevOffer };
        const nextNotes = toArray(nextOffer.agent_influencer_notes);

        const formData = new FormData();
        Object.entries(payload).forEach(([k, v]) => v && formData.append(k, v));

        const { data } = await api.post(`${endpoints.agent_campaign}influencer_notes`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        nextNotes.push(data);
        nextOffer.agent_influencer_notes = nextNotes;
        commit('setNewRowsData', nextOffer);
        commit('setWaitState', WaitState.isSuccess);
        return data;
      } catch (error) {
        commit('setWaitState', WaitState.isFailure);
        return null;
      }
    },
    async deleteBloggerNote({ commit, dispatch }, payload) {
      try {
        const url = `${endpoints.agent_campaign}influencer_notes`;
        await callConfirmDeletionModal(api, url, payload.id, payload.textOption);
        await dispatch('fetchData');

        commit('setSelectionState', []);
        commit('setWaitState', WaitState.default);
      } catch (e) {
        console.error(e);
      }
    },
    // CUSTOM COLUMNS API
    async addCustomColumn({ dispatch, state }, payload) {
      const campaignId = state.currentCampaignId;
      const url = `${endpoints.agent_campaign}${campaignId}/extra_columns`;
      const { data } = await api.post(url, payload);
      await dispatch('revalidateTableState', [...Object.values(state.customColumns), data]);
      return data;
    },
    async editCustomColumn({ dispatch, state }, payload) {
      const campaignId = state.currentCampaignId;
      const url = `${endpoints.agent_campaign}${campaignId}/extra_columns`;
      const { data } = await api.patch(url, payload);
      const nextState = { ...state.customColumns };
      nextState[data.id] = data;
      await dispatch('revalidateTableState', Object.values(nextState));
      return data;
    },
    async deleteCustomColumn({ commit, dispatch, state }, payload) {
      const campaignId = state.currentCampaignId;
      const url = `${endpoints.agent_campaign}${campaignId}/extra_columns`;
      await api.delete(url, payload);
      const nextState = { ...state.customColumns };
      delete nextState[payload.column_id];
      await dispatch('revalidateTableState', Object.values(nextState));
      commit('removeCustomColumnValuesFromData', payload.column_id);
    },
    async loadTableState({ commit }, campaignId) {
      let persistentData = await database.getRecord(String(campaignId));

      if (!persistentData) {
        persistentData = getDefaultCampaignRecord(String(campaignId));
      }

      commit('setCampaignState', persistentData);
    },
    async revalidateTableState({ commit, state }, customColumns) {
      let persistentData = await database.getRecord(String(state.currentCampaignId));

      if (!persistentData) {
        persistentData = getDefaultCampaignRecord(String(state.currentCampaignId));
        await database.addRecord(persistentData);
      }

      if (isEqual(Object.values(state.customColumns), customColumns)) {
        return;
      }

      const customColumnKeys = customColumns.map((c) => toCustomColumnKey(c.id));
      const validColumnKeys = [...Object.keys(DefaultHidingState), ...customColumnKeys];

      const prevHidingState = persistentData.hidingState ?? structuredClone(DefaultHidingState);
      persistentData.hidingState = validColumnKeys.reduce((s, key) => {
        s[key] = prevHidingState[key] ?? false;
        return s;
      }, {});

      commit('setCampaignState', persistentData);
      commit('setCustomColumns', customColumns);
    },
  },
  getters: {
    data: (state) => state.data ?? [],
    table: (state) => state.table,
    customColumns: (state) => state.customColumns,
    sortingState: (state) => state.sortingState ?? [],
    paginationState: (state) => state.paginationState ?? structuredClone(DefaultPaginationState),
    selectionState: (state) => state.selectionState ?? [],
    hidingState: (state) => state.hidingState ?? structuredClone(DefaultHidingState),
    statusFilter: (state) => state.statusFilter ?? structuredClone(DefaultStatusFilterState),
    waitState: (state) => state.waitState ?? WaitState.default,
  },
};
