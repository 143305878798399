<script>
import { Icon } from '@iconify/vue2';
import { mapGetters } from 'vuex';

import { modalService } from '@/services/modal.service';
import { ModalName } from '@/values/modalName';

export default {
  name: 'CustomColumnHeader',
  components: { Icon },
  props: {
    table: { type: Object, default: () => ({}) },
    column: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters({
      customColumns: 'agencyCabinetModule/customColumns',
    }),
    columnKey() {
      return this.column.key;
    },
    meta() {
      return this.columnDefs[this.columnKey]?.meta || {};
    },
    columnId() {
      return this.meta.id;
    },
    data() {
      return this.customColumns[this.columnId];
    },
    title() {
      return this.data?.title;
    },
  },
  methods: {
    onEdit() {
      modalService().open(ModalName.EditCustomColumnModal, { columnId: this.columnId });
    },
    onDelete() {
      modalService().open(ModalName.DeleteCustomColumnModal, { columnId: this.columnId });
    },
  },
};
</script>

<template>
  <div class="custom-column-header">
    <div :title="title" class="custom-column-header__title">
      {{ title }}
    </div>
    <b-dropdown
      class="column-header-menu"
      menu-class="column-header-menu__popover"
      no-caret
      right
      size="sm"
      toggle-class="column-header-menu__trigger"
      variant="none"
    >
      <template #button-content>
        <Icon height="20px" icon="bi:three-dots" width="20px" />
      </template>

      <b-dropdown-item-button button-class="column-header-menu__item" @click.native="onEdit">
        <Icon height="20px" icon="bx:pencil" width="20px" />
        <translate>Edit</translate>
      </b-dropdown-item-button>

      <b-dropdown-item-button
        button-class="column-header-menu__item column-header-menu__item--dangerous"
        @click.native="onDelete"
      >
        <Icon height="20px" icon="bx:trash" width="20px" />
        <translate>Delete</translate>
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<style lang="scss">
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .custom-column-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    width: 100%;
    height: 100%;

    &__title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      flex-grow: 1;
      white-space: wrap;
      word-break: break-all;
    }
  }

  .column-header-menu {
    &__trigger {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      color: colors.$primary-grey-light;
      border-radius: 8px;
      width: 36px;
      height: 36px;
      transition: all 0.3s;
      margin-right: 4px;

      &:hover {
        background: colors.$primary-lavender-dark !important;
        color: colors.$brand-color !important;
      }

      &:active {
        background: colors.$primary-lavender !important;
        color: colors.$brand-color-dark !important;
        border-color: colors.$primary-lavender !important;
      }
    }

    &__popover {
      background: colors.$primary-white;
      border-color: colors.$primary-white;
      box-shadow: 1px 3px 20px 0 #302a6f0f;
      border-radius: 16px;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      color: #212126;
      min-width: 185px;
    }

    &__item.column-header-menu__item--dangerous {
      color: colors.$primary-red;
    }

    &__item:hover {
      background: colors.$primary-lavender-dark;
    }

    &__item.active,
    &__item:active {
      background: colors.$primary-lavender;
    }
  }
</style>
