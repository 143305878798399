<script>
import { Icon } from '@iconify/vue2';

import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import BaseStatusLabel from '@/components/ui/BaseStatusLabel.vue';
import BaseSwitch from '@/components/ui/BaseSwitch.vue';
import { TableFilterDropdown, TableFilterDropdownItem } from '@/components/ui/tables';
import { OfferStatus } from '@/values/offerStatus';

export default {
  name: 'CampaignInfluencersTableToolbar',
  components: {
    BaseStatusLabel,
    BaseCheckbox,
    BaseSwitch,
    TableFilterDropdownItem,
    TableFilterDropdown,
    Icon,
  },
  props: {
    selectionState: { type: Array, required: true, default: () => [] },
    hidingFilter: { type: Object, required: true, default: () => ({}) },
    hidingFilterConfig: {
      type: Object,
      required: true,
      default: () => ({ baseColumns: {}, customColumns: {} }),
    },
    statusFilter: { type: Object, required: true, default: () => ({}) },
    statusFilterConfig: { type: Object, required: true, default: () => ({}) },
  },
  emits: [
    'update:hiding-filter',
    'update:status-filter',
    'reset:selection-state',
    'attach:specification',
    'remove:rows',
    'edit:rows',
  ],
  data: () => ({
    OfferStatus,
    isShowBaseColumnsSection: true,
    isShowCustomColumnsSection: true,
  }),
  computed: {
    isSelectedAllStatuses() {
      const selectedValues = Object.values(this.statusFilter).filter(Boolean);
      return Object.keys(this.statusFilter).length === selectedValues.length;
    },
    isSelectedSomeStatuses() {
      const selectedValues = Object.values(this.statusFilter).filter(Boolean);
      return Boolean(selectedValues.length) && !this.isSelectedAllStatuses;
    },
    baseColumnsConfig() {
      return Object.entries(this.hidingFilterConfig.baseColumns);
    },
    customColumnsConfig() {
      return Object.entries(this.hidingFilterConfig.customColumns);
    },
    isBaseColumnsHidden() {
      const data = this.baseColumnsConfig
        .map(([filterKey]) => this.hidingFilter[filterKey])
        .filter(Boolean);
      return this.baseColumnsConfig.length === data.length;
    },
    isCustomColumnsHidden() {
      const data = this.customColumnsConfig
        .map(([filterKey]) => this.hidingFilter[filterKey])
        .filter(Boolean);
      return this.customColumnsConfig.length === data.length;
    },
    isColumnsHidden() {
      return this.isBaseColumnsHidden && this.isCustomColumnsHidden;
    },
  },
  methods: {
    onToggleAllStatusState(value) {
      this.$emit(
        'update:status-filter',
        Object.keys(this.statusFilter).reduce((acc, key) => ((acc[key] = value), acc), {}),
      );
    },
    onChangeStatusState(key, value) {
      this.$emit('update:status-filter', { ...this.statusFilter, [key]: value });
    },
    onChangeHidingState(key, value) {
      this.$emit('update:hiding-filter', { ...this.hidingFilter, [key]: value });
    },
    onToggleBaseColumns() {
      const nextState = this.baseColumnsConfig.reduce(
        (state, [filterKey]) => {
          state[filterKey] = !this.isBaseColumnsHidden;
          return state;
        },
        { ...this.hidingFilter },
      );
      this.$emit('update:hiding-filter', nextState);
    },
    onToggleCustomColumns() {
      const nextState = this.customColumnsConfig.reduce(
        (state, [filterKey]) => {
          state[filterKey] = !this.isCustomColumnsHidden;
          return state;
        },
        { ...this.hidingFilter },
      );
      this.$emit('update:hiding-filter', nextState);
    },
    onToggleAllColumns() {
      const nextState = [...this.baseColumnsConfig, ...this.customColumnsConfig].reduce(
        (state, [filterKey]) => {
          state[filterKey] = !this.isColumnsHidden;
          return state;
        },
        { ...this.hidingFilter },
      );
      this.$emit('update:hiding-filter', nextState);
    },
  },
};
</script>

<template>
  <div class="ci-table-toolbar">
    <template v-if="selectionState.length">
      <div class="ci-table-toolbar__text">
        <translate>Selected</translate>
        {{ selectionState.length }}
      </div>
      <button
        class="ci-table-toolbar__action ci-table-toolbar--secondary"
        @click="$emit('reset:selection-state')"
      >
        <translate>Deselect all</translate>
      </button>
      <div class="ci-table-toolbar__divider" />
      <button
        v-if="selectionState.length > 1"
        class="ci-table-toolbar__action ci-table-toolbar--primary"
        @click="$emit('attach:specification')"
      >
        <Icon height="20" icon="bx:paperclip" style="transform: rotate(135deg)" width="20" />
        <translate>Attach specification</translate>
      </button>
      <button
        v-if="selectionState.length > 1"
        class="ci-table-toolbar__action ci-table-toolbar--primary"
        @click="$emit('edit:rows')"
      >
        <Icon height="20" icon="bx:edit" width="20" />
        <translate>Edit</translate>
      </button>
      <button
        class="ci-table-toolbar__action ci-table-toolbar--dangerous"
        @click="$emit('remove:rows')"
      >
        <Icon height="20" icon="bx:trash" width="20" />
        <translate>Delete</translate>
      </button>
      <slot />
    </template>

    <template v-else>
      <TableFilterDropdown
        :title="$gettext('All statuses')"
        icon="bx:filter-alt"
        style="--table-filter-dropdown-width: 240px; --base-status-label-size: 13px"
      >
        <TableFilterDropdownItem control-align="right">
          <translate>All statuses</translate>
          <template #control>
            <BaseCheckbox
              :checked="isSelectedAllStatuses"
              :indeterminate="isSelectedSomeStatuses"
              @update:checked="onToggleAllStatusState"
            />
          </template>
        </TableFilterDropdownItem>
        <template v-for="[filterKey, filterConfig] in Object.entries(statusFilterConfig)">
          <TableFilterDropdownItem :key="filterKey" control-align="right">
            <BaseStatusLabel
              :arrow="filterConfig.legacy.arrow"
              :arrow-dir="filterConfig.legacy.arrowDir"
              :status="filterKey"
              :status_translate="filterConfig.title"
            />
            <template #control>
              <BaseCheckbox
                :checked="statusFilter[filterKey]"
                @update:checked="onChangeStatusState(filterKey, $event)"
              />
            </template>
          </TableFilterDropdownItem>
        </template>
      </TableFilterDropdown>

      <TableFilterDropdown
        :title="$gettext('Columns')"
        class="table-filter"
        icon="ci:table-add"
        style="--table-filter-dropdown-width: 300px"
      >
        <section class="table-filter__section">
          <header class="table-filter__header">
            <translate>Base columns</translate>

            <button
              class="table-filter__collapse-control"
              type="button"
              @click="onToggleBaseColumns"
              v-text="isBaseColumnsHidden ? $gettext('Show all') : $gettext('Hide all')"
            />
          </header>

          <main class="table-filter__content">
            <template v-for="[filterKey, filterConfig] in baseColumnsConfig">
              <TableFilterDropdownItem :key="filterKey">
                {{ filterConfig.title }}
                <template #control>
                  <BaseSwitch
                    :checked="!hidingFilter[filterKey]"
                    :switch-id="`switch-input-${filterKey}`"
                    @update:checked="onChangeHidingState(filterKey, !$event)"
                  />
                </template>
              </TableFilterDropdownItem>
            </template>
          </main>
        </section>

        <hr>

        <section class="table-filter__section">
          <header class="table-filter__header" style="padding-top: 0">
            <translate>Custom columns</translate>
            <button
              class="table-filter__collapse-control"
              type="button"
              @click="onToggleCustomColumns"
              v-text="isCustomColumnsHidden ? $gettext('Show all') : $gettext('Hide all')"
            />
          </header>

          <main class="table-filter__content">
            <template v-for="[filterKey, filterConfig] in customColumnsConfig">
              <TableFilterDropdownItem :key="filterKey">
                {{ filterConfig.title }}
                <template #control>
                  <BaseSwitch
                    :checked="!hidingFilter[filterKey]"
                    :switch-id="`switch-input-${filterKey}`"
                    @update:checked="onChangeHidingState(filterKey, !$event)"
                  />
                </template>
              </TableFilterDropdownItem>
            </template>
          </main>
        </section>

        <hr>

        <section class="table-filter__section">
          <header class="table-filter__header" style="padding-top: 0">
            <button
              class="table-filter__collapse-control"
              type="button"
              @click="onToggleAllColumns"
              v-text="isColumnsHidden ? $gettext('Show all columns') : $gettext('Hide all columns')"
            />
          </header>
        </section>
      </TableFilterDropdown>

      <slot />
    </template>
  </div>
</template>

<style lang="scss">
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .ci-table-toolbar {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px 25px;
    user-select: none;

    &__divider {
      height: 18px;
      width: 1px;
      border-radius: 1px;
      background: colors.$secondary-white-grey;
    }

    &__text {
      @include fonts.raleway-medium;
      @include fonts.fontSize-big;
      color: colors.$primary-grey-light;
      white-space: nowrap;
    }

    &__action {
      @include fonts.raleway-medium;
      @include fonts.fontSize-big;
      position: relative;
      display: flex;
      align-items: center;
      gap: 5px;
      transition: all 0.3s;
      z-index: 0;
      white-space: nowrap;

      &::before {
        content: '';
        position: absolute;
        inset: -12px -10px;
        border-radius: 12px;
        background: colors.$primary-white;
        border: 1px solid transparent;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: all 0.6s;
      }

      &:hover::before,
      &:active::before {
        opacity: 1;
        visibility: visible;
      }

      & > svg {
        margin-bottom: 2px;
        transition: transform 0.6s;
      }

      &.ci-table-toolbar--secondary {
        color: colors.$secondary-grey;

        &:active::before {
          opacity: 1;
          visibility: visible;
          border: 1px solid colors.$secondary-grey;
        }
      }

      &.ci-table-toolbar--primary {
        color: colors.$brand-color;

        &:active::before {
          border: 1px solid colors.$brand-color;
        }
      }

      &.ci-table-toolbar--dangerous {
        color: colors.$primary-red;

        &:active::before {
          border: 1px solid colors.$primary-red;
        }
      }
    }
  }

  .table-filter {
    &__section {
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px 12px 4px 22px;
      color: colors.$primary-black;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }

    &__content {
      width: 100%;
    }

    &__collapse-control {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      font-size: 14px;
      line-height: 20px;
      color: colors.$brand-color;

      &:hover {
        color: colors.$brand-color-dark;
      }

      &:active {
        color: colors.$brand-color-gradient-end;
      }
    }
  }
</style>
