<script>
import BaseAvatar from '@/components/ui/BaseAvatar.vue';
import { modalService } from '@/services/modal.service';
import { ModalName } from '@/values/modalName';
import { OfferStatus } from '@/values/offerStatus';

export default {
  name: 'InfluencerInfoCell',
  components: { BaseAvatar },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  computed: {
    isDisabled() {
      return Boolean(this.row.originalValue.status === OfferStatus.Deleted);
    },
    picture() {
      return this.row.originalValue.influencer_profile_pic ?? '';
    },
    displayName() {
      return this.row.originalValue.full_name ?? '';
    },
    username() {
      return this.row.originalValue.influencer_network_account ?? '';
    },
  },
  methods: {
    openProfile() {
      modalService().open(ModalName.BloggerModal, this.row.originalValue);
    },
  },
};
</script>

<template>
  <button
    :disabled="isDisabled"
    :inert="isDisabled"
    class="influencer-info-cell"
    @click="openProfile"
  >
    <BaseAvatar :src="picture" class="influencer-info-cell__avatar" />
    <span class="influencer-info-cell__group">
      <span class="influencer-info-cell__name">{{ displayName }}</span>
      <span class="influencer-info-cell__username">@{{ username }}</span>
    </span>
  </button>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .influencer-info-cell {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    color: colors.$primary-black;
    cursor: pointer;

    &__group {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 14px 10px 14px 0;
      overflow: hidden;
      text-align: left;
    }

    &__name {
      @include fonts.fontSize-big;
      @include fonts.raleway-bold;
    }

    &__username {
      @include fonts.fontSize-normal;
      @include fonts.raleway-regular;
      color: colors.$primary-grey;
    }

    &__name,
    &__username {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__avatar {
      min-width: 32px;
      width: 32px;
      height: 32px;
      aspect-ratio: 1;
      margin: 14px 0 14px 10px;
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
</style>
