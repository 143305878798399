<script>
import isEqual from 'lodash-es/isEqual';
import { createNamespacedHelpers } from 'vuex';

import { SelectCellField } from '@/components/ui/tables';
import { OfferStatus } from '@/values/offerStatus';
import { Currency } from '@/values/payment';

import { createTablePatchPayload } from '../tableHelpers';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'InfluencerCurrencyCell',
  components: { SelectCellField },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    innerValue: null,
  }),
  computed: {
    isDisabled() {
      return Boolean(this.row.originalValue.status === OfferStatus.Deleted);
    },
    currentColumn() {
      return this.cell.col?.key ?? '';
    },
    value() {
      return this.cell.value ?? null;
    },
    config() {
      return {
        [Currency.USD]: this.$gettext('USD'),
        [Currency.EUR]: this.$gettext('EUR'),
        [Currency.RUB]: this.$gettext('RUB'),
        [Currency.BYN]: this.$gettext('BYN'),
        [Currency.KZT]: this.$gettext('KZT'),
      };
    },
    options() {
      return Object.entries(this.config).map(([key, c]) => ({ key, text: c }));
    },
  },
  watch: {
    value: {
      handler(v) {
        this.innerValue = v ? { key: v, text: this.config[v]?.title ?? v } : null;
      },
      immediate: true,
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({ updateRow: 'updateRow' }),
    onRevert() {
      this.innerValue = this.value
        ? { key: this.value, text: this.config[this.value] ?? this.value }
        : null;
    },
    async onSubmit(option = {}) {
      const prevValue = this.value;
      const nextValue = option.key ?? '';

      if (isEqual(prevValue, nextValue) || !nextValue) {
        return;
      }

      const payload = createTablePatchPayload(this.row.originalValue, {
        [this.currentColumn]: nextValue,
      });

      this.innerValue = option;
      const isSubmitted = await this.updateRow(payload);
      !isSubmitted && this.onRevert();
    },
  },
};
</script>

<template>
  <SelectCellField
    :disabled="isDisabled"
    :options="options"
    :value="innerValue"
    @revert="onRevert"
    @submit="onSubmit"
  />
</template>
