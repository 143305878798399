import { ModalTableCell, TextTableCell } from '@/components/ui/tables';
import { toArray, toNumberString } from '@/functions/normalize';
import { queryPluralText } from '@/functions/utils';
import { ModalName } from '@/values/modalName';
import { OfferStatus } from '@/values/offerStatus';
import {
  calcReachFromRest,
  calcReachFromSubscribers,
  calcSuspiciousPercent,
} from '@/widgets/CampaignInfluencersTable/tableFormulas';

import BloggerNotesCell from './components/BloggerNotesCell.vue';
import InfluencerCurrencyCell from './components/InfluencerCurrencyCell.vue';
import InfluencerDateCell from './components/InfluencerDateCell.vue';
import InfluencerInfoCell from './components/InfluencerInfoCell.vue';
import InfluencerMetricsCell from './components/InfluencerMetricsCell.vue';
import InfluencerPaymentCell from './components/InfluencerPaymentCell.vue';
import InfluencerPriceCell from './components/InfluencerPriceCell.vue';
import InfluencerStatusCell from './components/InfluencerStatusCell.vue';
import OfferNotesCell from './components/OfferNotesCell.vue';

export const ColumnKey = Object.freeze({
  InfluencerInfo: 'full_name',
  Network: 'network',
  ER: 'influencer_er',
  Followers: 'influencer_follower_count',
  Status: 'status',
  TargetReach: 'target_reach',
  ActualReach: 'actual_reach',
  FollowersReach: 'reach_followers',
  Description: 'description',
  Date: 'placement_date',
  Currency: 'currency',
  Price: 'price',
  Commission: 'commission',
  TotalPrice: 'total_price',
  PaidAmount: 'spend',
  PaymentMethod: 'payment_method',
  BloggerNotes: 'blogger_notes',
  Content: 'content',
  OfferNotes: 'offer_notes',
  Actions: 'actions',
  ReachPercentFromSubscribers: 'reach_percent_from_subscribers',
  ReachPercentFromRest: 'reach_percent_from_rest',
  SuspiciousSubscriptionsPercent: 'suspicious_subscriptions_percent',
});

export const FormatCell = Object.freeze({
  Float: 'float',
  Currency: 'currency',
});

export const AlwaysVisibleColumnKeys = [ColumnKey.InfluencerInfo];

export const DefaultHidingState = {
  ...Object.values(ColumnKey)
    .filter((c) => !AlwaysVisibleColumnKeys.includes(c))
    .reduce((s, key) => ((s[key] = false), s), {}),
  [ColumnKey.ReachPercentFromSubscribers]: true,
  [ColumnKey.ReachPercentFromRest]: true,
  [ColumnKey.SuspiciousSubscriptionsPercent]: true,
};

export const DefaultPaginationState = {
  page: 1,
  perPage: 10,
  totalCount: null,
  pageOptions: [10, 25, 50, 100],
};

export const DefaultStatusFilterState = Object.values(OfferStatus).reduce((s, key) => {
  s[key] = ![OfferStatus.Deleted].includes(key);
  return s;
}, {});

export function createCampaignInfluencersTableColumns({ columnTitles = {}, cellTexts = {} }) {
  return [
    {
      key: ColumnKey.InfluencerInfo,
      header: columnTitles[ColumnKey.InfluencerInfo] || '',
      cell: InfluencerInfoCell,
      enableSorting: true,
      enableResizing: true,
      enableSticky: true,
      size: { width: 194 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.Followers,
      accessor: (r) => r.originalValue.influencer_follower_count,
      header: columnTitles[ColumnKey.Followers] || '',
      cell: InfluencerMetricsCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 120 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.ER,
      accessor: (r) => r.originalValue.influencer_er,
      header: columnTitles[ColumnKey.ER] || '',
      cell: InfluencerMetricsCell,
      enableSorting: true,
      enableResizing: true,
      meta: { format: FormatCell.Float },
      size: { width: 120 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.Status,
      accessor: (r) => r.originalValue.status,
      header: columnTitles[ColumnKey.Status] || '',
      cell: InfluencerStatusCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.TargetReach,
      accessor: (r) => r.originalValue.target_reach,
      header: columnTitles[ColumnKey.TargetReach] || '',
      cell: InfluencerMetricsCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.ActualReach,
      accessor: (r) => r.originalValue.actual_reach,
      header: columnTitles[ColumnKey.ActualReach] || '',
      cell: InfluencerMetricsCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.FollowersReach,
      accessor: (r) => r.originalValue.reach_followers,
      header: columnTitles[ColumnKey.FollowersReach] || '',
      cell: InfluencerMetricsCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.Actions,
      accessor: (r) => r.originalValue.actions,
      header: columnTitles[ColumnKey.Actions] || '',
      cell: InfluencerMetricsCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 120 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.Description,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.Description] || '',
      cell: ModalTableCell,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
      meta: {
        icon: '',
        targetModal: ModalName.TaskListModal,
        displayTextAccessor: (data) => {
          return data.description?.name || '';
        },
        isDisabled: (data) => {
          return data.status === OfferStatus.Deleted;
        },
      },
    },
    {
      key: ColumnKey.Date,
      accessor: (r) => r.originalValue.placement_date,
      header: columnTitles[ColumnKey.Date] || '',
      cell: InfluencerDateCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.Currency,
      accessor: (r) => r.originalValue.currency,
      header: columnTitles[ColumnKey.Currency] || '',
      cell: InfluencerCurrencyCell,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.Price,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.Price] || '',
      cell: InfluencerPriceCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.Commission,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.Commission] || '',
      cell: InfluencerPriceCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.TotalPrice,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.TotalPrice] || '',
      cell: InfluencerPriceCell,
      enableResizing: true,
      size: { width: 194 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.PaidAmount,
      accessor: (r) => r.originalValue.spend,
      header: columnTitles[ColumnKey.PaidAmount] || '',
      cell: InfluencerMetricsCell,
      enableSorting: true,
      enableResizing: true,
      size: { width: 194 },
      meta: { format: FormatCell.Currency },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.PaymentMethod,
      accessor: (r) => r.originalValue.payment_method,
      header: columnTitles[ColumnKey.PaymentMethod] || '',
      cell: InfluencerPaymentCell,
      enableResizing: true,
      size: { width: 194 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.Content,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.Content] || '',
      cell: ModalTableCell,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
      meta: {
        icon: '',
        targetModal: ModalName.TableContentModal,
        displayTextAccessor: (data) => {
          const filesCount =
            toArray(data.drafts).length +
            toArray(data.screenshots).length +
            toArray(data.reports).length +
            (data.link ? 1 : 0);
          return filesCount ? queryPluralText(filesCount, cellTexts.filesPlural) : '';
        },
        isDisabled: (data) => {
          return data.status === OfferStatus.Deleted;
        },
      },
    },
    {
      key: ColumnKey.OfferNotes,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.OfferNotes] || '',
      cell: OfferNotesCell,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.BloggerNotes,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.BloggerNotes] || '',
      cell: BloggerNotesCell,
      enableResizing: true,
      size: { width: 160 },
      style: { row: 'table-cell--editable' },
    },
    {
      key: ColumnKey.ReachPercentFromSubscribers,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.ReachPercentFromSubscribers] || '',
      cell: TextTableCell,
      enableResizing: true,
      size: { width: 120 },
      meta: {
        displayTextAccessor: ({ actual_reach, influencer_follower_count }) => {
          return toNumberString(
            calcReachFromSubscribers(actual_reach, influencer_follower_count),
            '%',
          );
        },
        isDisabled: (data) => {
          return data.status === OfferStatus.Deleted;
        },
      },
    },
    {
      key: ColumnKey.ReachPercentFromRest,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.ReachPercentFromRest] || '',
      cell: TextTableCell,
      enableResizing: true,
      size: { width: 120 },
      meta: {
        displayTextAccessor: ({ actual_reach, reach_followers }) => {
          return toNumberString(calcReachFromRest(actual_reach, reach_followers), '%');
        },
        isDisabled: (data) => {
          return data.status === OfferStatus.Deleted;
        },
      },
    },
    {
      key: ColumnKey.SuspiciousSubscriptionsPercent,
      accessor: (r) => r.originalValue,
      header: columnTitles[ColumnKey.SuspiciousSubscriptionsPercent] || '',
      cell: TextTableCell,
      enableResizing: true,
      size: { width: 120 },
      meta: {
        displayTextAccessor: ({ audience_types }) => {
          return toNumberString(calcSuspiciousPercent(audience_types), '%');
        },
        isDisabled: (data) => {
          return data.status === OfferStatus.Deleted;
        },
      },
    },
  ];
}
