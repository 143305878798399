<script>
import { mapActions, mapGetters } from 'vuex';

import BaseProvidedModal from '@/components/ui/BaseProvidedModal.vue';
import BaseSolidButton from '@/components/ui/BaseSolidButton.vue';
import { modalService } from '@/services/modal.service';
import { ModalName } from '@/values/modalName';

export default {
  name: 'DeleteCustomColumnModal',
  components: {
    BaseSolidButton,
    BaseProvidedModal,
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      customColumns: 'agencyCabinetModule/customColumns',
    }),
    id() {
      return ModalName.DeleteCustomColumnModal;
    },
    modalPayload() {
      return modalService().getState(this.id)?.payload ?? {};
    },
    columnId() {
      return this.modalPayload?.columnId;
    },
  },
  methods: {
    ...mapActions({
      deleteCustomColumn: 'agencyCabinetModule/deleteCustomColumn',
    }),
    async onSubmit() {
      try {
        this.isLoading = true;
        await this.deleteCustomColumn({ column_id: this.columnId });
        modalService().close(this.id);
      } catch {
        this.$notify({
          group: 'app',
          type: 'error',
          text: 'An error occurred when deleting a custom column. Please try again or contact your administrator',
        });
      } finally {
        this.isLoading = false;
      }
    },
    onCancel() {
      modalService().close(this.id);
    },
  },
};
</script>

<template>
  <BaseProvidedModal
    :id="id"
    :closable="true"
    :close-out-backdrop="false"
    :scrollable="false"
    :title="$gettext('Delete column')"
    size="md"
    title-align="center"
    @hide="onCancel"
  >
    <div class="delete-custom-column-form">
      <div class="delete-custom-column-form__description">
        <translate>Are you sure you want to delete a custom column?</translate>
        <br>
        <translate>The data in the column will be lost.</translate>
      </div>
      <div class="delete-custom-column-form__actions">
        <BaseSolidButton
          :loading="isLoading"
          :style="{ 'min-width': '185px' }"
          :text="$gettext('Delete')"
          size="md"
          variant="dangerous"
          @click="onSubmit"
        />
        <BaseSolidButton
          :style="{ 'min-width': '185px' }"
          :text="$gettext('Cancel')"
          size="md"
          variant="primary"
          @click="onCancel"
        />
      </div>
    </div>
  </BaseProvidedModal>
</template>

<style lang="scss" scoped>
  @use '@/style/colors.scss' as colors;
  @use '@/style/fonts.scss' as fonts;

  .delete-custom-column-form {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__description {
      @include fonts.raleway-regular;
      @include fonts.fontSize-big;
      color: colors.$primary-black;
      margin: 0;
      text-align: center;
      line-height: 20px;
    }

    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
  }
</style>
