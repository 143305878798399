<script>
import { Icon } from '@iconify/vue2';
import { nextTick } from 'vue';

import BaseDivider from '@/components/ui/BaseDivider.vue';

import InfluencerPopoverUploader from './InfluencerPopoverUploader.vue';
import NotesCellNoteCard from './NotesCellNoteCard.vue';

export default {
  name: 'InfluencerNotesCellContent',
  components: {
    BaseDivider,
    InfluencerPopoverUploader,
    NotesCellNoteCard,
    Icon,
  },
  props: {
    notes: { type: Array, required: true, default: () => [] },
    title: { type: String, required: true, default: '' },
    placeholderTitle: { type: String, required: true, default: '' },
    placeholderDescription: { type: String, required: true, default: '' },
  },
  emits: ['submit', 'close:note', 'delete:note'],
  data: () => ({
    activeNoteId: null,
  }),
  watch: {
    notes: {
      handler() {
        this.scrollToBottom();
      },
      deep: true,
    },
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      nextTick(() => {
        if (this.$refs.content) {
          this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
        }
      });
    },
    setActiveNote(id) {
      this.activeNoteId = this.activeNoteId === id ? null : id;
    },
  },
};
</script>

<template>
  <div class="notes-cell-content">
    <div class="notes-cell-content__header">
      <span>{{ title }}</span>
      <button @click="$emit('close:note')">
        <Icon class="notes-cell-content__icon" icon="bx:x" width="30" />
      </button>
    </div>

    <BaseDivider />
    <div v-if="notes.length > 0" ref="content" class="notes-cell-content__content">
      <NotesCellNoteCard
        v-for="note in notes"
        :key="note.id"
        :active-note-id="activeNoteId"
        :note="note"
        @delete:note="$emit('delete:note', $event)"
        @set:active-note="setActiveNote"
      />
    </div>

    <div v-else class="notes-cell-content__no-content">
      <div class="notes-cell-content__no-content-header">
        {{ placeholderTitle }}
      </div>
      {{ placeholderDescription }}
    </div>

    <BaseDivider />
    <InfluencerPopoverUploader
      :placeholder="$gettext('Enter text')"
      file-upload
      @submit="$emit('submit', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .notes-cell-content {
    @include fonts.fontSize-big;
    @include fonts.raleway-medium;

    display: flex;
    flex-direction: column;
    width: 520px;
    max-height: 640px;
    color: colors.$primary-black;
    overflow: hidden;

    *::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    *::-webkit-scrollbar-track {
      background: #f6f6f6;
      margin: 15px 0;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #e1d7ff;
      border-radius: 12px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: colors.$primary-black;
      font-size: 18px;
      @include fonts.raleway-bold;
      padding: 10px 20px;
    }

    &__icon {
      cursor: pointer;
      color: colors.$secondary-light-grey;
    }

    &__no-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 10px;
      height: 33vh;
      overflow-y: auto;
      scrollbar-width: auto;
      padding: 10px 20px;
      @include fonts.fontSize-normal;
      @include fonts.raleway-medium;
      color: colors.$primary-grey;

      &-header {
        font-size: 18px;
        @include fonts.raleway-bold;
        color: colors.$primary-black;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      height: 33vh;
      overflow-y: auto;
      scrollbar-width: auto;
    }
  }
</style>
