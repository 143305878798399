<script>
import { Icon } from '@iconify/vue2';

import { toDateFirstDateTimeString } from '@/functions/normalize';
import { photoExtensions, videoExtensions } from '@/values/mediaExtensions';

export default {
  name: 'NotesCellNoteCard',
  components: { Icon },
  props: {
    note: { type: Object, required: true },
    activeNoteId: { type: Number, default: null },
  },
  emits: ['delete:note', 'set:active-note'],
  data: () => ({
    optionsVisible: false,
    fileExtension: '',
  }),
  computed: {
    displayName() {
      const { first_name, last_name, email } = this.note.user || {};
      return first_name && last_name
        ? `${first_name} ${last_name}`
        : first_name || last_name || email || '';
    },
    displayDate() {
      return toDateFirstDateTimeString(this.note.created_at);
    },
    displayMessage() {
      return this.note.text || '';
    },
    isMediaFile() {
      return (
        this.fileExtension &&
        (photoExtensions.has(this.fileExtension) || videoExtensions.has(this.fileExtension))
      );
    },
    isVideoFile() {
      return this.isMediaFile && videoExtensions.has(this.fileExtension);
    },
    documentFiles() {
      return !this.isMediaFile;
    },
    mediaFiles() {
      return this.isMediaFile;
    },
  },
  watch: {
    activeNoteId(newVal) {
      this.optionsVisible = this.note.id === newVal;
    },
  },
  mounted() {
    this.fileExtension = this.getFileExtension(this.note.name);
  },
  methods: {
    getFileExtension(title) {
      const titleParts = title.split('.');
      return titleParts.length ? `.${titleParts.pop().toLowerCase()}` : '';
    },
    handleDoubleClick() {
      this.$emit('set:active-note', this.note.id);
    },
    copyNote() {
      navigator.clipboard.writeText(this.displayMessage).then(() => this.handleDoubleClick());
    },
    deleteNote() {
      this.handleDoubleClick();
      this.$emit('delete:note', this.note.id);
    },
  },
};
</script>

<template>
  <div :class="['note-card', { 'note-card--focus': optionsVisible }]" @dblclick="handleDoubleClick">
    <div class="note-card__header">
      <span>{{ displayName }}</span>
      <span class="note-card__header-date">{{ displayDate }}</span>
    </div>
    <div class="note-card__content">
      {{ displayMessage }}
    </div>
    <div v-if="mediaFiles" class="note-card__files">
      <div class="note-card__file">
        <video v-if="isVideoFile" class="note-card__files-video" controls>
          <source :src="note.attachment">
        </video>
        <img v-else :src="note.attachment" alt="" class="note-card__files-img">
      </div>
    </div>

    <div v-if="documentFiles && note.attachment" class="note-card__files">
      <div class="note-card__file">
        <a :href="note.attachment" class="note-card__file-link" target="_blank">
          <Icon icon="ci:file-blank" style="margin-bottom: 2px" width="20" />
          <span>{{ note.name }}</span>
        </a>
      </div>
    </div>

    <div v-show="optionsVisible" class="note-card__options">
      <button class="note-card__option" @click="copyNote">
        <Icon icon="ci:copy" width="20" />
        <translate>Copy</translate>
      </button>
      <button class="note-card__option note-card__option-danger" @click.stop="deleteNote">
        <Icon icon="ci:trash-full" width="20" />
        <translate>Delete</translate>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;
  @use '@/style/breakpoints.scss' as breakpoints;

  .note-card {
    @include fonts.fontSize-big;
    @include fonts.raleway-regular;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px 20px;
    color: colors.$primary-black;

    &.note-card--focus {
      background-color: colors.$secondary-light-brand;
    }

    &__header {
      @include fonts.fontSize-big;
      @include fonts.raleway-semibold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: colors.$primary-black;

      &-date {
        @include fonts.fontSize-normal;
        @include fonts.raleway-regular;
        color: colors.$primary-grey;
      }
    }

    &__files {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &-img,
      &-video {
        border-radius: 4px;
        width: 100%;
      }
    }

    &__file:hover {
      text-decoration: underline;
    }

    &__file-link {
      @include fonts.raleway-semibold;
      display: contents;
      color: colors.$brand-color;
      text-decoration: none;
    }

    &__options {
      position: absolute;
      top: 20px;
      left: 150px;
      width: 204px;
      background-color: colors.$primary-white;
      display: flex;
      flex-direction: column;
      z-index: 10;
      border-radius: 12px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    &__option {
      @include fonts.fontSize-big;
      @include fonts.raleway-medium;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 20px;
      color: colors.$primary-black;

      &-danger {
        color: colors.$primary-red;
      }

      &:hover {
        background-color: colors.$primary-lavender;
      }
    }
  }
</style>
