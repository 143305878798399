<script>
import isEqual from 'lodash-es/isEqual';
import { createNamespacedHelpers } from 'vuex';

import { SelectCellField } from '@/components/ui/tables';
import { OfferStatus } from '@/values/offerStatus';
import { createPaymentMethodConfig, PaymentMethod } from '@/values/payment';

import { createTablePatchPayload } from '../tableHelpers';

const agencyCabinetStore = createNamespacedHelpers('agencyCabinetModule');

export default {
  name: 'InfluencerPaymentCell',
  components: { SelectCellField },
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  data: () => ({
    innerValue: null,
  }),
  computed: {
    isDisabled() {
      return Boolean(this.row.originalValue.status === OfferStatus.Deleted);
    },
    currentColumn() {
      return this.cell.col?.key ?? '';
    },
    value() {
      return this.cell.value ?? null;
    },
    config() {
      return createPaymentMethodConfig({
        [PaymentMethod.Advy]: this.$gettext('Advy'),
        [PaymentMethod.Direct]: this.$gettext('Direct'),
      });
    },
    options() {
      return Object.entries(this.config).map(([key, c]) => ({ key, text: c.title }));
    },
  },
  watch: {
    value: {
      handler(v) {
        this.innerValue = v ? { key: v, text: this.config[v]?.title ?? v } : null;
      },
      immediate: true,
    },
  },
  methods: {
    ...agencyCabinetStore.mapActions({ updateRow: 'updateRow' }),
    onRevert() {
      this.innerValue = this.value
        ? { key: this.value, text: this.config[this.value]?.title ?? this.value }
        : null;
    },
    async onSubmit(option = {}) {
      const prevValue = this.value;
      const nextValue = option.key ?? '';

      if (isEqual(prevValue, nextValue) || !nextValue) {
        return;
      }

      const payload = createTablePatchPayload(this.row.originalValue, {
        [this.currentColumn]: nextValue,
      });

      this.innerValue = option;
      const isSubmitted = await this.updateRow(payload);
      !isSubmitted && this.onRevert();
    },
  },
};
</script>

<template>
  <SelectCellField
    :disabled="isDisabled"
    :options="options"
    :value="innerValue"
    @revert="onRevert"
    @submit="onSubmit"
  />
</template>
