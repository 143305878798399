<script>
import { Icon } from '@iconify/vue2';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'DateCellField',
  components: { Icon, DatePicker },
  props: {
    value: { type: [Date, String], required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
  },
  emits: ['submit', 'click'],
};
</script>

<template>
  <date-picker
    :class="['date-cell-field', { 'date-cell-field--empty': !value }]"
    :clearable="false"
    :disabled="disabled"
    :editable="false"
    :value="value"
    format="DD.MM.YYYY"
    input-class="date-cell-field__control"
    popup-class="date-cell-field__popup"
    @change="$emit('submit', $event)"
    @click="$emit('click', $event)"
  >
    <template #icon-calendar>
      <Icon
        v-if="!disabled"
        :icon="!value ? 'ci:calendar-add' : 'ci:calendar'"
        aria-hidden="true"
        class="date-cell-field__icon"
      />
      <span v-else />
    </template>
  </date-picker>
</template>

<style lang="scss">
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .date-cell-field {
    @include fonts.fontSize-big;
    @include fonts.raleway-medium;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    color: colors.$primary-black;

    --icon-size: 28px;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    & &__icon {
      color: colors.$brand-color;
      background-color: colors.$primary-lavender-dark;
      border-radius: 8px;
      padding: 5px;
      width: var(--icon-size, 28px) !important;
      height: var(--icon-size, 28px) !important;
    }

    &__control {
      @include fonts.fontSize-big;
      @include fonts.raleway-medium;
      color: colors.$primary-black;
      background-color: transparent;
      border: none;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      height: 100%;
      width: 100%;
      flex-grow: 1;
      padding: 14px calc(20px + var(--icon-size, 28px)) 14px 10px;
    }

    .mx-input-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    & .mx-icon-calendar {
      right: 10px;
      pointer-events: none;
    }

    &.date-cell-field--empty {
      .mx-icon-calendar {
        right: auto;
        left: 10px;
      }
    }
  }

  .date-cell-field__popup {
    @include fonts.raleway-regular;
    border-radius: 16px;
    color: colors.$primary-black;
    border: none;

    .mx-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
    }

    .mx-btn-text {
      @include fonts.raleway-semibold;
      @include fonts.fontSize-normal;
      color: colors.$primary-black;

      &:hover,
      &:active {
        color: colors.$brand-color;
      }
    }

    .mx-calendar-header-label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      text-align: center;
    }

    .cell {
      border-radius: 8px;

      &:hover {
        color: colors.$brand-color;
        background-color: colors.$primary-background;
      }

      &.today {
        @include fonts.raleway-semibold;
        color: colors.$brand-color;
        background-color: colors.$primary-background;
      }

      &.active {
        @include fonts.raleway-semibold;
        color: colors.$primary-white;
        background-color: colors.$brand-color;
      }
    }
  }
</style>
