<script>
export default {
  name: 'TextTableCell',
  props: {
    table: { type: Object, default: () => ({}) },
    row: { type: Object, default: () => ({}) },
    cell: { type: Object, default: () => ({}) },
    columnDefs: { type: Object, default: () => ({}) },
  },
  computed: {
    currentColumn() {
      return this.cell.col?.key ?? '';
    },
    value() {
      return this.cell.value;
    },
    meta() {
      return this.columnDefs[this.currentColumn].meta || {};
    },
    config() {
      return {
        displayTextAccessor:
          typeof this.meta.displayTextAccessor === 'function'
            ? this.meta.displayTextAccessor
            : () => '',
        isDisabled:
          typeof this.meta.isDisabled === 'function' ? this.meta.isDisabled : () => false,
      };
    },
    displayedText() {
      return this.config.displayTextAccessor(this.value);
    },
    isDisabled() {
      return this.config.isDisabled(this.value);
    },
  },
};
</script>

<template>
  <span :data-disabled="isDisabled" :inert="isDisabled" class="text-table-cell">
    {{ displayedText }}
  </span>
</template>

<style lang="scss" scoped>
  @use '@/style/fonts.scss' as fonts;
  @use '@/style/colors.scss' as colors;

  .text-table-cell[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
